import React, { useState, useEffect } from 'react'
import {
  Card,
  CardHeader,
  Row,
  Col,
  Table,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormGroup,
  CardBody,
  CardTitle,
  Button,
} from 'reactstrap'
import ReactDatetime from 'react-datetime'

import { alertDanger, alertSuccess } from 'utils/toast'
import api from 'configs/api'
import Loading from 'components/Loading'
import moment from 'moment'
import useFetch from 'hooks/useFetch'
import { formatOS } from 'utils/functions'
import { useExcelDownloder } from 'react-xls'
import { log } from 'console'

function Metrics({ select }: any) {
  const { ExcelDownloder, Type } = useExcelDownloder()
  const [dados, setDados] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [dates, setDates] = useState<any>({
    start: moment().format('01/MM/YYYY'),
    end: moment().format('DD/MM/YYYY'),
  })
  const [download, setDownload] = useState<any>({
    id: 0,
    data: null,
  })

  const { data: appointments } = useFetch(
    `schedulings/ms/v1/reports/status?start=${dates.start}&end=${dates.end}&country=${select?.sigla}&embed=total`,
    true,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const { data: schedulings } = useFetch(
    `schedulings/ms/v1/reports/users?start=${dates.start}&end=${dates.end}&country=${select?.sigla}`,
    true,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const getDate = async () => {
    const { start, end } = dates

    if (!start && end) {
      return alertDanger('Preencha os períodos!')
    }
    setLoading(true)
    try {
      const { data } = await api.get(
        `accounts/ms/v1/reports/users/international?start=${start}&end=${end}&country=${select?.sigla}`
      )

      const usersIds = data.users.map((user: any) => user.id)

      if (usersIds.length > 0) {
        const { data: appointmentsData } = await api.post(
          `schedulings/ms/v1/reports/users/has-appointments`,
          {
            users_id: usersIds,
          }
        )

        const usersWithAppointments =
          appointmentsData.length > 0
            ? data.users.map((user: any) => {
                const hasAppointment = appointmentsData.includes(user.id)
                return { ...user, has_appointment: hasAppointment }
              })
            : data.users
        setDados({ ...data, users: usersWithAppointments })
      } else {
        setDados(data)
      }
      setDownload({
        id: 0,
        data: null,
      })
    } catch (error) {
      alertDanger('Ocorreu algum erro')
    }
    setLoading(false)
    return true
  }

  // eslint-disable-next-line consistent-return
  const getAppointmentsReport = async () => {
    const { start, end } = dates
    if (!start || !end) {
      return alertDanger('Por favor, selecione um período válido.')
    }
    setLoading(true)
    try {
      if (dados && !dados?.users?.length) {
        alertDanger('Não foi encontrado nenhum dado')
        setLoading(false)
        return true
      }

      const headers = [
        'Nome',
        'E-mail',
        'Telefone',
        'País',
        'Sistema',
        'Data de Cadastro',
        'Agendou consulta',
      ]

      const xlsxData = []

      if (dados?.users && dados?.users?.length > 0) {
        xlsxData.push(['Relatório de Novos Cadastros'])
        xlsxData.push([''])
        xlsxData.push([`Período: ${start} a ${end}`])
        xlsxData.push([''])
        xlsxData.push(headers)

        dados.users.forEach((user: any) => {
          xlsxData.push([
            user.name,
            user.email,
            user.cellphone,
            user.country,
            user.os,
            moment(user.created_at).format('DD/MM/YYYY'),
            user.has_appointment ? 'Sim' : 'Não',
          ])
        })
      }

      setDownload({
        id: 'appointments_report',
        data: {
          'Relatório de Novos Cadastros': xlsxData,
        },
      })

      alertSuccess('Sucesso!! Para fazer o download, clique no botão "baixar"')
    } catch (error) {
      alertDanger('Ocorreu algum erro')
    }
    setLoading(false)
  }

  useEffect(() => {
    getDate()
  }, [dates, select])

  return (
    <>
      {loading && <Loading title="Aguarde..." />}
      <Row className="d-flex align-items-center justify-content-between">
        <Col sm="auto" className="mb-2 col-sm">
          <h3 className="mb-0 text-black">{`Dados gerais - ${select.name}`}</h3>
        </Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-between">
        <Col className="col-sm">
          <Card
            className="card-stats mb-4 mb-lg-3 shadow"
            style={{ height: 110 }}
          >
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    Total de estrelas
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-black">
                    {dados?.allUsers ?? 0}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-users" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-sm">
          <Card
            className="card-stats mb-4 mb-lg-3 shadow"
            style={{ height: 110 }}
          >
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    Total de compra de consulta
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-black">
                    {dados?.allProducts ?? 0}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-dollar-sign" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-sm">
          <Card
            className="card-stats mb-4 mb-lg-3 shadow"
            style={{ height: 110 }}
          >
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    Total de consultas finalizadas
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-black">
                    {appointments?.appointments_total?.finished ?? 0}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-calendar-check" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-between">
        <Col sm="auto" className="mb-2 col-sm">
          <h3 className="mb-0 text-black">Dados por período</h3>
          <p className="text-gray">
            {`Os dados abaixo são referente ao período de ${dates.start} até ${dates.end}.`}
          </p>
        </Col>
        <Row className="mt-4 ml-2 mr-1 align-items-center ">
          <Col xs={12} md={5}>
            <FormGroup>
              <h5 className="mb-2 text-black">Data inicial</h5>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: 'Selecione a data inicial',
                  }}
                  value={dates.start}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  closeOnSelect
                  onChange={(e: any) => {
                    setDates({
                      ...dates,
                      start: moment(e.toDate()).format('DD/MM/YYYY'),
                    })
                  }}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={12} md={5}>
            <FormGroup>
              <h5 className="mb-2 text-black">Data final</h5>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: 'Selecione a data final',
                  }}
                  value={dates.end}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  closeOnSelect
                  isValidDate={(current) => {
                    const startDate = moment(dates.start, 'DD/MM/YYYY').startOf(
                      'day'
                    )
                    const selectedMonth = current.month()
                    return (
                      current.isSameOrAfter(startDate, 'day') &&
                      current.month() === selectedMonth
                    )
                  }}
                  onChange={(e: any) => {
                    setDates({
                      ...dates,
                      end: moment(e.toDate()).format('DD/MM/YYYY'),
                    })
                  }}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </Row>
      <Row className="d-flex align-items-center justify-content-end">
        <Col sm="auto" className="mb-2 col-sm">
          {dados?.users?.length > 0 && download.data === null ? (
            <Button color="primary" onClick={() => getAppointmentsReport()}>
              Gerar relatório
            </Button>
          ) : null}

          {download.data && (
            <ExcelDownloder
              data={download.data}
              filename={`${dates.start}-${dates.end}`}
              type={Type.Button}
            >
              <Button
                color="primary"
                onClick={() => {
                  setDownload({
                    id: 0,
                    data: null,
                  })
                }}
              >
                <i className="fas fa-download mr-2" />
                Baixar
              </Button>
            </ExcelDownloder>
          )}
        </Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-between">
        <Col className="col-sm">
          <Card
            className="card-stats mb-4 mb-lg-3 shadow"
            style={{ height: 110 }}
          >
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    Novas estrelas
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-black">
                    {dados?.usersTotal ?? 0}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-users" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-sm">
          <Card
            className="card-stats mb-4 mb-lg-3 shadow"
            style={{ height: 110 }}
          >
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    Compra de consulta
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-black">
                    {dados?.productsPurchases ?? 0}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-dollar-sign" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-sm">
          <Card
            className="card-stats mb-4 mb-lg-0 shadow"
            style={{ height: 110 }}
          >
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    Consultas agendadas
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-black">
                    {appointments?.appointments?.pending ?? 0}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-calendar-plus" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-sm">
          <Card
            className="card-stats mb-4 mb-lg-0 shadow"
            style={{ height: 110 }}
          >
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    Consultas finalizadas
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-black">
                    {appointments?.appointments?.finished ?? 0}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-calendar-check" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Card className="shadow">
        <CardHeader className="bg-transparent border-0">
          <Row className="d-flex align-items-center justify-content-between">
            <Col sm="auto" className="mb-2">
              <h3 className="mb-0 text-black">Novos cadastros</h3>
            </Col>
          </Row>
        </CardHeader>
        <Row className="mt-0">
          <div className="col">
            <Card
              className="shadow"
              style={{ maxHeight: '400px', overflowY: 'auto' }}
            >
              <Table
                className="align-items-center table-flush"
                responsive
                hover
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Pais</th>
                    <th scope="col">Sistema</th>
                    <th scope="col">Data de cadastro</th>
                    <th scope="col">Agendou consulta</th>
                  </tr>
                </thead>
                <tbody>
                  {dados?.users.map((user: any) => (
                    <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>
                        {user.ddi}
                        {user.cellphone}
                      </td>
                      <td>{user.country}</td>
                      <td>{formatOS(user.os)}</td>
                      <td>{moment(user.created_at).format('DD/MM/YYYY')}</td>
                      <td>{user.has_appointment ? 'Sim' : 'Não'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Card>
      {/* <Card className="shadow mt-4">
        <CardHeader className="bg-transparent border-0">
          <Row className="d-flex align-items-center justify-content-between">
            <Col sm="auto" className="mb-2">
              <h3 className="mb-0 text-black">
                Estrelas que agendaram consulta
              </h3>
            </Col>
          </Row>
        </CardHeader>
        <Row className="mt-0">
          <div className="col">
            <Card
              className="shadow"
              style={{ maxHeight: '400px', overflowY: 'auto' }}
            >
              <Table
                className="align-items-center table-flush"
                responsive
                hover
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Pais</th>
                    <th scope="col">Data da consulta</th>
                  </tr>
                </thead>
                <tbody>
                  {schedulings?.appointments.map((user: any) => (
                    <tr key={user.id}>
                      <td>{user.user_name}</td>
                      <td>{user.user_email}</td>
                      <td>{user.user_cellphone}</td>
                      <td>{user.user_country}</td>
                      <td>
                        {moment(user.from_date_time).format('DD/MM/YYYY')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Card> */}
    </>
  )
}

export default Metrics
